import React from 'react';
import logo from './logo.svg';
import './App.css';
import Demo from './human-pose/human-pose';

function App() {
  return (
    <div className="App">
      <Demo />
    </div>
  );
}

export default App;
