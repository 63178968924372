import React, { createRef } from 'react';
import { Button, Spin, Dropdown, Toast } from '@douyinfe/semi-ui';
import {
    PoseLandmarker,
    FilesetResolver,
    DrawingUtils
} from "@mediapipe/tasks-vision";
import './human-pose.css'


let poseLandmarker: PoseLandmarker;
let runningMode = "IMAGE";
// let enableWebcamButton: HTMLElement | null;
// let webcamRunning: Boolean = false;
// const videoHeight = "360px";
// const videoWidth = "480px";

// let streamSettings: MediaTrackSettings

class Demo extends React.Component {
    maskRef: HTMLDivElement = undefined as any
    videoRef: HTMLVideoElement = undefined as any
    canvasRef: HTMLCanvasElement = undefined as any

    windowInnerHeight: number = 0
    windowInnerWidth: number = 0
    currentWidth: number = 0
    currentHeight: number = 0
    canvasCtx: any = undefined
    drawingUtils: DrawingUtils = undefined as any
    streamSettings: MediaTrackSettings = undefined as any

    // webcamRunning: Boolean = false

    constructor(props:any) {
        super(props)
        this.state = {
            // video_src: undefined
            webcamRunning: false,
            delegateDisabled: false,
            // cameraSuccess: false,
            delegate: 'GPU'
        }
        // this.videoRef = createRef()
        // this.videoRef = undefined as any
        // this.canvasRef = undefined as any
        // this.windowInnerHeight = 0
        // this.windowInnerWidth = 0
        // this.streamSettings = undefined as any
        // this.canvasCtx = undefined
        // this.drawingUtils = undefined as any
    }

    lastVideoTime = -1
    predictWebcam = async () => {
        // console.log("streamSettings: ", this.streamSettings)

        // Now let's start detecting the stream.
        if (runningMode === "IMAGE") {
            runningMode = "VIDEO";
            await poseLandmarker.setOptions({ runningMode: "VIDEO" });
        }
        let startTimeMs = performance.now();

        // console.log("this.lastVideoTime: ", this.lastVideoTime)
        // console.log("this.videoRef.currentTime: ", this.videoRef.currentTime)
        // console.log("this.videoRef:", this.videoRef)
        if (this.lastVideoTime !== this.videoRef.currentTime) {
            this.lastVideoTime = this.videoRef.currentTime;
            poseLandmarker.detectForVideo(this.videoRef, startTimeMs, (result) => {
                // console.log("result：", result)

                this.canvasCtx.save();
                this.canvasCtx.clearRect(0, 0, this.currentWidth, this.currentHeight);
                for (const landmark of result.landmarks) {
                    this.drawingUtils.drawLandmarks(landmark, {
                        radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 2, 1),
                        lineWidth: 1
                    })
                    this.drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS, {
                        lineWidth: 1
                    });
                }
                this.canvasCtx.restore();
            });
        }
    
        // Call this function again to keep predicting when the browser is ready.
        if (( this.state as any).webcamRunning === true) {
            window.requestAnimationFrame(this.predictWebcam);
        }
    }


    // Before we can use PoseLandmarker class we must wait for it to finish
    // loading. Machine Learning models can be large and take a moment to
    // get everything needed to run.
    createPoseLandmarker = async () => {
        const vision = await FilesetResolver.forVisionTasks(
        "./wasm"
        );
        poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
            modelAssetPath: `./pose_landmarker_lite.task`,
            delegate: (this.state as any).delegate
        },
        runningMode: runningMode as any,
        numPoses: 2
        });
        // demosSection.classList.remove("invisible");

        this.maskRef.style.display = 'none'
    };

    componentDidMount() {

        this.createPoseLandmarker()
        this.windowInnerHeight = window.innerHeight
        this.windowInnerWidth = window.innerWidth

        // this.canvasCtx = this.canvasRef.current!.getContext("2d");
        // this.drawingUtils = new DrawingUtils(this.canvasCtx);



        // const video = document.getElementById("webcam") as HTMLVideoElement;
        // const canvasElement = document.getElementById(
        // "output_canvas"
        // ) as HTMLCanvasElement;
        // const canvasCtx = canvasElement.getContext("2d");
        // const drawingUtils = new DrawingUtils(canvasCtx as any);

        // Check if webcam access is supported.
        const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

        // If webcam supported, add event listener to button for when user
        // wants to activate it.
        if (!hasGetUserMedia()) {
            console.warn("getUserMedia() is not supported by your browser");
        }
    }

    setVideoRef = (element:HTMLVideoElement)=>{
        this.videoRef = element
    }

    setCanvasRef = (element:HTMLCanvasElement)=>{
        this.canvasRef = element

        this.canvasCtx = element.getContext("2d");
        this.drawingUtils = new DrawingUtils(this.canvasCtx);
    }

    setMaskRef = (element:HTMLDivElement)=>{
        this.maskRef = element
    }


    // Enable the live webcam view and start detection.
    enableCam = (event:any) => {
        if (!poseLandmarker) {
            console.log("Wait! poseLandmaker not loaded yet.");
            return;
        }

        // this.webcamRunning = true
        if ((this.state as any).webcamRunning === true) {
            this.setState({
                webcamRunning: false
            })
            // this.webcamRunning = false;
            // enableWebcamButton.innerText = "ENABLE PREDICTIONS";
        } else {
            this.setState({
                webcamRunning: true
            })
            // this.webcamRunning = true;
            // enableWebcamButton.innerText = "DISABLE PREDICTIONS";
        }

        this.setState({
            delegateDisabled: true
        })
    
        // getUsermedia parameters.
        const constraints = {
            video: true
        };
    
        // Activate the webcam stream.
        navigator.mediaDevices.getUserMedia(constraints).then((stream:MediaStream) => {
            // this.setState({
            //     video_src: stream,
            // });

            this.videoRef.srcObject = stream

            this.streamSettings = stream.getVideoTracks()[0].getSettings()

            let ratio = this.windowInnerHeight / ( this.streamSettings.height! + 200 )
            // if (this.streamSettings.height! > this.windowInnerHeight) {
            //     ratio = this.windowInnerHeight / ( this.streamSettings.height! + 200 )
            // }else {
            //     ratio = this.streamSettings.height! / ( this.windowInnerHeight + 200 )
            // }

            // if (this.streamSettings.width! > this.windowInnerWidth) {
            //     let t = this.windowInnerWidth / this.streamSettings.width!
            //     if (t > ratio) ratio = t
            // }else {
            //     let t = this.streamSettings.width! / this.windowInnerWidth
            //     if (t < ratio) ratio = t
            // }

            // 不缩小了，反而要放大好一些
            // if (this.windowInnerWidth - this.currentWidth < 100) {
            //     ratio = ratio * 0.8
            //     this.currentWidth = this.streamSettings.width! * ratio
            // }
            

            this.currentHeight = this.streamSettings.height! * ratio
            this.currentWidth = this.streamSettings.width! * ratio
            
            let left = ( this.windowInnerWidth - this.currentWidth ) / 2

            this.videoRef.style.height = this.currentHeight + 'px';
            this.videoRef.style.width = this.currentWidth + 'px';
            this.videoRef.style.left = left + 'px';
            this.canvasRef.style.height = this.currentHeight + 'px';
            this.canvasRef.style.width = this.currentWidth + 'px';
            this.canvasRef.style.left = left + 'px';


            // console.log("相机加载完成！")
            // this.setState({
            //     cameraSuccess: true,
            // })

            // this.videoRef.current!.addEventListener("loadeddata", this.predictWebcam);
            // video.srcObject = stream;
            this.videoRef.addEventListener("loadeddata", this.predictWebcam);
        });
    }

    delegateClick = (value:any) => {
        // console.log("value: ", value)
        this.setState({
            delegate: value
        })
    }

    render() {
        return (
            <div>
                <div ref={this.setMaskRef} className='mask'>
                    <div style={{height: '100%', display: 'flex', flexWrap: 'wrap', alignContent: 'center'}}>
                        <div style={{display: 'block', width: '100%'}}>
                            <Spin size="large" tip="模型下载中，第一次下载需要几分钟甚至更久，请您耐心等待...">
                                <div />
                            </Spin>
                        </div>
                    </div>
                </div>
                <h2>武当入世</h2>
                <h4>纯视觉3D骨骼关键点 轻量模型演示</h4>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                    <div>
                        <Button theme='solid' type='primary' size='large' style={{marginRight: '50px'}} onClick={this.enableCam}>{(this.state as any).webcamRunning ? '停止识别' : '开始识别'}</Button>

                        <Dropdown
                            trigger={'hover'}
                            position={'bottom'}
                            render={
                                <Dropdown.Menu>
                                    <Dropdown.Item disabled={( this.state as any ).delegateDisabled} onClick={() => this.delegateClick('GPU')}>使用GPU进行运算</Dropdown.Item>
                                    <Dropdown.Item disabled={( this.state as any ).delegateDisabled} onClick={() => this.delegateClick('CPU')}>使用CPU进行运算</Dropdown.Item>
                                </Dropdown.Menu>
                            }
                        >
                            <Button size='large'>工作引擎选择：{ (this.state as any).delegate }</Button>
                        </Dropdown>    
                    </div>

                    <div style={{marginTop: '50px', position: 'relative'}}>
                        <video ref={this.setVideoRef} id="webcam" className='webcam' autoPlay playsInline></video>
                        <canvas ref={this.setCanvasRef} id="output_canvas" className='output_canvas'></canvas>
                    </div>
                </div>
            </div>
        );
    }
}

export default Demo